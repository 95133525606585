/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FeesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Fees Total
     * total fees paid from all pools since beginning
     * @returns any Successful Response
     * @throws ApiError
     */
    public feesTotal(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fees/v1/total',
        });
    }

    /**
     * Fees Spent Pools All
     * fees paid on 24h and 7d timeframe for all pools
     * @returns any Successful Response
     * @throws ApiError
     */
    public feesSpentPoolsAll(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fees/v1/pools',
        });
    }

    /**
     * Fees Spent Pool
     * fees paid on 24h and 7d timeframe from a choosen pools
     * @param poolId
     * @returns any Successful Response
     * @throws ApiError
     */
    public feesSpentPool(
        poolId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fees/v1/{pool_id}',
            path: {
                'pool_id': poolId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Fees Historical
     * total fees historically paid daily since the beginning
     * @returns any Successful Response
     * @throws ApiError
     */
    public feesHistorical(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fees/v1/total/historical',
        });
    }

    /**
     * Fees Historical Pool
     * total fees historically paid daily since the beginning for a given pool
     * @param poolId
     * @returns any Successful Response
     * @throws ApiError
     */
    public feesHistoricalPool(
        poolId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fees/v1/historical/{pool_id}',
            path: {
                'pool_id': poolId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
