import {captureException} from '@sentry/remix';

import {withAbortTimeout} from '@rockawayxlabs/observatory-utils';

export async function fetchSkipValidatorPerformance() {
  try {
    const API_URL = process.env.SKIP_API_URL;

    const response = await withAbortTimeout(({signal}) => fetch(`${API_URL}/v1/validator_performance`, {signal}), {
      timeoutMs: 5000,
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch Skip validator performance. Endpoint responded with ${response.status}: ${response.statusText}`
      );
    }

    const result = await response.json().catch(() => {
      throw new Error('Failed to parse json from Skip validator performance response');
    });

    assertValidSkipValidatorPerformanceResponse(result);

    return result.performance;
  } catch (error) {
    captureException(error);
    throw error;
  }
}

interface SkipValidatorPerformanceResponse {
  performance: {
    operatorAddress: string;
    category: '0' | '1' | '2' | '3';
  }[];
  error: {message: string} | null;
}

export function assertValidSkipValidatorPerformanceResponse(
  result: unknown
): asserts result is SkipValidatorPerformanceResponse {
  function throwInvalidResponseError(message: string, skipErrorMessage?: unknown): never {
    let errorMessage = `Invalid Skip validator performance response: ${message}.`;
    if (skipErrorMessage) {
      errorMessage += `\nSkip error message: ${skipErrorMessage}.`;
    }

    throw new Error(errorMessage);
  }

  if (!result || typeof result !== 'object') {
    throwInvalidResponseError(`Expected object, got ${typeof result}`);
  }

  if (!('error' in result)) {
    throwInvalidResponseError('Missing required field "error"');
  }

  if (typeof result.error !== 'object') {
    throwInvalidResponseError(`Expected field "error" to be an object, got ${typeof result.error}`);
  }

  if (result.error && !('message' in result.error)) {
    throwInvalidResponseError('Missing required field "error.message"');
  }

  if (!('performance' in result)) {
    throwInvalidResponseError('Missing required field "performance"', result.error?.message);
  }

  if (!Array.isArray(result.performance)) {
    throwInvalidResponseError(
      `Expected field "performance" to be an array, got ${typeof result.performance}`,
      result.error?.message
    );
  }

  const hasInvalidValidatorRecord = result.performance.some(
    ({operatorAddress, category}) =>
      typeof operatorAddress !== 'string' || ['0', '1', '2', '3'].indexOf(category) === -1
  );

  if (hasInvalidValidatorRecord) {
    throwInvalidResponseError('Response contains invalid validator record', result.error?.message);
  }
}
