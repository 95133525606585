import classes from './route.module.css';

import { Alert, Badge, Stack, Text, TextInput } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { useSearchParams } from '@remix-run/react';
import { IconCoin, IconCoinOff, IconSearch } from '@tabler/icons-react';
import { useMemo } from 'react';

import { LabeledCard } from '~/components/LabeledCard';
import { useLoaderData } from '~/features/serialization';
import { NumberFormat } from '~/utils/NumberFormat';

import { DecentralizationTile } from './components/DecentralizationTile';
import { DurationParamControl } from './components/DurationParamControl';
import { GovernanceTile } from './components/GovernanceTile';
import { MevDiscrepancyTile } from './components/MevDiscrepancyTile';
import { NewsfeedTile } from './components/NewsfeedTile';
import { PerformanceTile } from './components/PerformanceTile';
import { PublicInfrastructureTile } from './components/PublicInfrastructureTile';
import { RevenueTile } from './components/RevenueTile';
import { DASHBOARD_DEFAULT_DURATION_PERIOD, DASHBOARD_DURATION_PERIODS, NONINFLATIONARY_ZONES } from './route.constants';
import type { loader } from './route.loader.server';

export { loader } from './route.loader.server';
export { meta } from './route.meta';
export { ErrorBoundary } from '~/components/AppErrorBoundary';

export default function ZonePage() {
  const {
    zoneId,
    syncedAt,
    zoneSlug,
    tokenSymbol,
    matchedStakeRatio,
    governance,
    performance,
    mevDiscrepancy,
    revenue,
    infrastructure,
    decentralization
  } = useLoaderData<typeof loader>();
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useDebouncedState('', 200);

  const selectedPeriodLabel = useMemo(() => {
    const period = searchParams.get('period');
    return DASHBOARD_DURATION_PERIODS.find((p) => p.value === period)?.label ?? DASHBOARD_DEFAULT_DURATION_PERIOD.label;
  }, [searchParams]);

  const periodBadge =
  <Badge variant="light" size="xs" color="gray">
      {selectedPeriodLabel}
    </Badge>;


  return (
    <div className={classes.root}>
      <DurationParamControl className={classes.durationControl} />

      <Stack gap="lg" className={classes.tiles}>
        <TextInput
          size="md"
          placeholder="Search validators"
          leftSection={<IconSearch size="1rem" />}
          defaultValue={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          className={classes.searchInput} />

        <GovernanceTile
          data={governance}
          periodBadge={periodBadge}
          syncedAt={syncedAt}
          zoneSlug={zoneSlug}
          tokenSymbol={tokenSymbol}
          search={search} />

        <PerformanceTile data={performance} periodBadge={periodBadge} syncedAt={syncedAt} search={search} />
        {mevDiscrepancy &&
        <MevDiscrepancyTile data={mevDiscrepancy} zoneSlug={zoneSlug} tokenSymbol={tokenSymbol} search={search} />}

        {NONINFLATIONARY_ZONES.includes(zoneSlug) ?
        <LabeledCard
          icon={<IconCoin size="1rem" />}
          label="Validator Revenue"
          anchor="validator-revenu"
          bodyProps={{ px: 'xl', py: 'md' }}>

            <Alert
            icon={<IconCoinOff size="1rem" />}
            title={`${tokenSymbol} token is not inflationary`}
            variant="outline" />

          </LabeledCard> :

        <RevenueTile data={revenue} periodBadge={periodBadge} tokenSymbol={tokenSymbol} search={search} />}

        <PublicInfrastructureTile data={infrastructure} periodBadge={periodBadge} syncedAt={syncedAt} />
        {matchedStakeRatio <= 0.8 &&
        <Alert className={classes.alert}>
            <Text span size="sm" fw={700} c="white">
              Stake not fully matched.
            </Text>{' '}
            <Text span size="sm" fw={500} c="white">
              We are only able to match nodes to validators corresponding to{' '}
              {NumberFormat.fractionalPercentage(matchedStakeRatio)} stake. Metrics can be skewed.
            </Text>
          </Alert>}

        <DecentralizationTile data={decentralization} syncedAt={syncedAt} />
        <NewsfeedTile zone={{ id: zoneId, slug: zoneSlug, tokenSymbol }} />
      </Stack>
    </div>);

}