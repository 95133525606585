import classes from './PublicInfrastructureTile.module.css';

import { Card, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { IconCloudComputing } from '@tabler/icons-react';
import cx from 'clsx';
import type { ReactNode } from 'react';

import { BarChart } from '~/components/BarChart';
import { LabeledCard } from '~/components/LabeledCard';
import { NodeFlagBadge } from '~/components/NodeFlags';
import { SyncBadge } from '~/components/SyncBadge';
import { TiledGrid } from '~/components/TiledGrid/TiledGrid';
import { NumberFormat } from '~/utils/NumberFormat';

import type { DashboardData } from '../model/types';

interface PublicInfrastructureTileProps {
  data: DashboardData['infrastructure'];
  periodBadge: ReactNode;
  syncedAt: Date;
}

export function PublicInfrastructureTile({
  data: {
    activeValidatorsCount,
    totalValidatorsCount,
    totalNodesCount,
    avgRpcReliability,
    publicRpcEndpointsCount,
    totalRpcEndpointsCount,
    nodeFlagsCounts,
    rpcEndpointsReliability
  },
  periodBadge,
  syncedAt
}: PublicInfrastructureTileProps) {
  const theme = useMantineTheme();

  return (
    <LabeledCard icon={<IconCloudComputing size="1rem" />} label="Public Infrastructure" anchor="public-infrastructure">
      <TiledGrid style={{ borderBottom: '0.5px solid var(--observatory-color-border)' }}>
        <TiledGrid.Tile
          span={{
            base: 12,
            md: 9
          }}
          order={{
            base: 2,
            md: 1
          }}>

          <Stack justify="space-between" gap="md" h="100%">
            <Group gap={5}>
              <Text size="sm" lineClamp={1} className={classes.label}>
                Public RPC Nodes Reliability
              </Text>
              {periodBadge}
            </Group>

            <BarChart
              mih={120}
              yAxisProps={{
                domain: [0, 1],
                hide: false,
                tickFormatter: (val) => NumberFormat.percentage(val) ?? ''
              }}
              data={rpcEndpointsReliability}
              valueKeys={['ratio']}
              cellColor={(value) => theme.other.getScoreColor(value * 100)}
              tooltip={({ label, ratio, successfulCallsCount, failedCallsCount }) =>
              <Card withBorder p="xs">
                  <Text size="sm" className={classes.value}>
                    {label}
                  </Text>

                  <Text size="xs" className={classes.label}>
                    <span>Successful calls </span>
                    <strong>
                      {NumberFormat.decimal(successfulCallsCount)} ({NumberFormat.fractionalPercentage(ratio)})
                    </strong>
                  </Text>
                  <Text size="xs" className={classes.label}>
                    <span>Failed calls </span>
                    <strong>
                      {NumberFormat.decimal(failedCallsCount)} ({NumberFormat.fractionalPercentage(1 - ratio)})
                    </strong>
                  </Text>
                </Card>}

              overlay={
              !rpcEndpointsReliability.length &&
              <div className={classes.overlay}>
                    <Text size="xs" tt="uppercase" className={cx(classes.overlayContent, classes.value)}>
                      No endpoints detected in selected time period
                    </Text>
                  </div>} />



          </Stack>
        </TiledGrid.Tile>
        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          order={{
            base: 1,
            md: 2
          }}
          label={
          <Group gap={5}>
              <span>Public RPC Nodes</span>
              {periodBadge}
            </Group>}

          value={NumberFormat.decimal(publicRpcEndpointsCount)}
          secondaryValue={NumberFormat.decimal(totalRpcEndpointsCount)}
          tooltip="Number of public RPC nodes out of all detected RPC nodes." />

      </TiledGrid>

      <TiledGrid>
        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 4
          }}
          label={
          <Group gap={5}>
              <span>Avg Public RPC Node Reliability</span>
              {periodBadge}
            </Group>}

          value={NumberFormat.fractionalPercentage(avgRpcReliability)} />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 4
          }}
          label={
          <Group gap={5}>
              <span>Active Validators</span>
              <SyncBadge syncedAt={syncedAt} />
            </Group>}

          value={NumberFormat.decimal(activeValidatorsCount)}
          secondaryValue={NumberFormat.decimal(totalValidatorsCount)}
          tooltip="Number of active validators out of all detected validators." />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 4
          }}
          label={
          <Group gap={5}>
              <span>Online Nodes</span>
              <SyncBadge syncedAt={syncedAt} />
            </Group>}

          value={NumberFormat.decimal(totalNodesCount)}
          tooltip="Number of online nodes detected in sync." />

        <TiledGrid.Tile span={12}>
          <Group justify="center">
            {[...nodeFlagsCounts.entries()].map(([flag, count]) =>
            <NodeFlagBadge
              key={flag}
              flag={flag}
              leftSection={`${count}`}
              rightSection={count === 1 ? 'Node' : 'Nodes'} />

            )}
          </Group>
        </TiledGrid.Tile>
      </TiledGrid>
    </LabeledCard>);

}