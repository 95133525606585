/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TokensService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Tokens All
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokensAll(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/all',
        });
    }

    /**
     * Tokens Mcap
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokensMcap(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/mcap',
        });
    }

    /**
     * Tokens By Symbol
     * @param symbol
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokensBySymbol(
        symbol: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/{symbol}',
            path: {
                'symbol': symbol,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Token Price Historical Chart V2
     * - **symbol**: Symbol of token to return
     * - **range**: Range of historical Data (Available values : 5,15,30,60,120,240,720,1440,10080,43800)
     * @param symbol
     * @param tf
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokenPriceHistoricalChartV2(
        symbol: string,
        tf: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/historical/{symbol}/chart',
            path: {
                'symbol': symbol,
            },
            query: {
                'tf': tf,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Liquidity By Tokens V2
     * @param symbol
     * @returns any Successful Response
     * @throws ApiError
     */
    public liquidityByTokensV2(
        symbol: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/liquidity/{symbol}/chart',
            path: {
                'symbol': symbol,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Volume By Tokens
     * @param symbol
     * @returns any Successful Response
     * @throws ApiError
     */
    public volumeByTokens(
        symbol: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/volume/{symbol}/chart',
            path: {
                'symbol': symbol,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tokens Price
     * Available tokens are:
     * - ** SYMBOL : https://info.osmosis.zone/tokens**
     * @param symbol
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokensPrice(
        symbol: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/price/{symbol}',
            path: {
                'symbol': symbol,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tokens Top Gainers Or Losers
     * type can be either 'gainers' or 'losers'
     * @param type
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokensTopGainersOrLosers(
        type: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/top/{type}',
            path: {
                'type': type,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tokens Count V2
     * @param poolId
     * @param rangeStart
     * @param rangeStop
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokensCountV2(
        poolId: number,
        rangeStart: string,
        rangeStop: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/count/{pool_id}',
            path: {
                'pool_id': poolId,
            },
            query: {
                'range_start': rangeStart,
                'range_stop': rangeStop,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tokens Volume Global
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokensVolumeGlobal(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/volume/global',
        });
    }

    /**
     * Volume By Tokens Chart V2
     * @param symbol
     * @returns any Successful Response
     * @throws ApiError
     */
    public volumeByTokensChartV2(
        symbol: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/volume/coin/{symbol}/chart',
            path: {
                'symbol': symbol,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Token Volume By Pool
     * @param poolId
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokenVolumeByPool(
        poolId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/volume/{pool_id}/coin/chart',
            path: {
                'pool_id': poolId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * @deprecated
     * Tokens Dominance
     * **REPLACE BY**: Nothing
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokensDominance(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tokens/v2/dominance/all',
        });
    }

}
