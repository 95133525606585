import classes from './ConcentrationBar.module.css';

import { Anchor, Group, HoverCard, ScrollArea, Stack, Text, Tooltip } from '@mantine/core';
import cx from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';

import { Link } from '~/features/links';
import type { DashboardStakeConcentration } from '~/routes/$zoneSlug._index/model/types';
import { NumberFormat } from '~/utils/NumberFormat';

interface ConcentrationBarProps extends ComponentPropsWithoutRef<'div'> {
  height?: number;
  data: DashboardStakeConcentration['stake'];
}

export function ConcentrationBar({ data, height = 18, className, ...others }: ConcentrationBarProps) {
  const { knownFragments, aggregatedFragments, aggregatedFraction, unknownFragment } = data;

  return (
    <div className={cx(classes.root, className)} {...others}>
      {knownFragments.map((fragment) =>
      <Tooltip
        key={fragment.label}
        label={`${fragment.label}: ${NumberFormat.fractionalPercentage(fragment.fraction)}`}>

          <Link
          to={fragment.link}
          className={cx(classes.bar, classes.barLink, {
            [classes.scoreHigh!]: fragment.fraction * 100 < 5,
            [classes.scoreMedium!]: fragment.fraction * 100 < 10,
            [classes.scoreLow!]: fragment.fraction * 100 < 15,
            [classes.scoreCritical!]: fragment.fraction * 100 >= 15
          })}
          style={{ height, width: `${fragment.fraction * 100}%` }}>

            <span>{fragment.label}</span>
          </Link>
        </Tooltip>
      )}
      {aggregatedFragments.length > 0 &&
      <HoverCard width={280} withinPortal>
          <HoverCard.Target>
            <div
            className={cx(classes.bar, classes.aggregated)}
            style={{ height, width: `${aggregatedFraction * 100}%` }} />

          </HoverCard.Target>
          <HoverCard.Dropdown className={classes.hoverCard}>
            <ScrollArea.Autosize mah={300} offsetScrollbars>
              <Stack gap={5}>
                {aggregatedFragments.map((cell) =>
              <Group key={cell.label} justify="space-between" wrap="nowrap">
                    <Anchor component={Link} to={cell.link} size="sm" lineClamp={1} className={classes.link}>
                      {cell.label}:
                    </Anchor>
                    <Text size="sm">{NumberFormat.fractionalPercentage(cell.fraction)}</Text>
                  </Group>
              )}
              </Stack>
            </ScrollArea.Autosize>
          </HoverCard.Dropdown>
        </HoverCard>}

      {unknownFragment &&
      <Tooltip label={`Unknown: ${NumberFormat.fractionalPercentage(unknownFragment.fraction)}`}>
          <div
          className={cx(classes.bar, classes.remainder)}
          style={{ height, width: `${unknownFragment.fraction * 100}%` }}>

            <div className={classes.centerLine} />
          </div>
        </Tooltip>}

    </div>);

}