import classes from './BarChart.module.css';

import { Box, type BoxProps, useMantineTheme } from '@mantine/core';
import { useCallback, useMemo } from 'react';
import { Bar, Cell, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import type { XAxisProps, YAxisProps } from 'recharts';

import { approximateStringMatch } from '@rockawayxlabs/observatory-utils';

const defaultYAxisProps: YAxisProps = {
  type: 'number',
  dataKey: 'value',
  domain: ['dataMin', 'dataMax'],
  allowDataOverflow: true,
  hide: true,
  interval: 'preserveStartEnd',
  tickCount: 3,
  tick: {
    fontSize: 10
  },
  strokeWidth: 0,
  width: 30
};

const defaultXAxisProps: XAxisProps = {
  type: 'category',
  dataKey: 'label',
  strokeWidth: 0,
  tickFormatter: () => '',
  hide: true
};

type ChartCell<ValueKey extends string> = {label: string;} & Record<ValueKey, number>;

interface BarChartProps<T extends ChartCell<U>, U extends string> extends BoxProps {
  data: T[];
  valueKeys?: U[];
  lineKeys?: U[];
  tooltip?: (data: T) => React.ReactNode;
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
  cellColor?: string | ((value: number, key: U, cell: T) => string);
  search?: string;
  overlay?: React.ReactNode;
}

export function BarChart<T extends ChartCell<U>, U extends string = 'value'>({
  data,
  valueKeys = (['value'] as U[]),
  lineKeys = ([] as U[]),
  tooltip = () => null,
  yAxisProps = {},
  xAxisProps = {},
  cellColor,
  search = '',
  overlay,
  ...others
}: BarChartProps<T, U>) {
  const theme = useMantineTheme();
  const yProps = { ...defaultYAxisProps, ...yAxisProps };
  const xProps = { ...defaultXAxisProps, ...xAxisProps };

  const colorFn = useCallback(
    (value: number, key: U, cell: T) => {
      if (typeof cellColor === 'undefined') {
        return theme.other.scoreColors.high;
      }
      return typeof cellColor === 'string' ? cellColor : cellColor(value, key, cell);
    },
    [cellColor, theme]
  );

  const labelMatched = useCallback(
    (label: string) => {
      if (!search) {
        return true;
      }

      return approximateStringMatch(label, search);
    },
    [search]
  );

  const barsWithCells = useMemo(
    () =>
    valueKeys.map((key) => ({
      key,
      minPointSize: valueKeys.length > 1 ? 0 : 4,
      cells: data.map((entry) => ({
        label: entry.label,
        fill: colorFn(entry[key], key, entry)
      }))
    })),
    [data, valueKeys, colorFn]
  );

  if (!data.length) {
    return (
      <Box {...others} w="100%" h="100%" pos="relative">
        {overlay}
      </Box>);

  }

  return (
    <Box {...others} w="100%" h="100%" pos="relative">
      {overlay}
      <ResponsiveContainer width="100%" height="100%" className={classes['responsive-container']}>
        <ComposedChart data={data}>
          <XAxis {...xProps} />
          <YAxis {...yProps} />
          <Tooltip
            content={({ active, payload }) => {
              if (!active || !payload?.[0]?.payload) {
                return null;
              }

              return tooltip(payload[0]?.payload);
            }}
            wrapperStyle={{ outline: 'none', zIndex: 100000 }}
            cursor={{
              opacity: 0.25,
              radius: 0
            }} />

          {barsWithCells.map(({ key, minPointSize, cells }) =>
          <Bar key={key} dataKey={key} stackId="a" maxBarSize={8} minPointSize={minPointSize} radius={0}>
              {cells.map(({ label, fill }) =>
            <Cell key={label} fill={fill} opacity={labelMatched(label) ? 1 : 0.1} />
            )}
            </Bar>
          )}
          {lineKeys.map((key) =>
          <Line
            key={key}
            type="linear"
            dot={false}
            dataKey={key}
            stroke={theme.other.scoreColors.high}
            strokeWidth={2} />

          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>);

}