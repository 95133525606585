import classes from './PerformanceTile.module.css';

import { Box, Card, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { IconCpu } from '@tabler/icons-react';
import type { ReactNode } from 'react';

import { BarChart } from '~/components/BarChart';
import { ChainParamsBadge } from '~/components/ChainParamsBadge';
import { LabeledCard } from '~/components/LabeledCard';
import { ProgressWithSharedTooltip } from '~/components/ProgressWithSharedTooltip/ProgressWithSharedTooltip';
import { ScoreRing } from '~/components/ScoreRing';
import { SyncBadge } from '~/components/SyncBadge';
import { TiledGrid } from '~/components/TiledGrid/TiledGrid';
import { NumberFormat } from '~/utils/NumberFormat';

import type { DashboardData } from '../model/types';

interface PerformanceTileProps {
  data: DashboardData['performance'];
  periodBadge: ReactNode;
  syncedAt: Date;
  search: string;
}

export function PerformanceTile({
  data: { score, signedBlocksWindow, signingPerformance, jailsCount, avgBlockTime, validators, blockTimeDistribution },
  periodBadge,
  syncedAt,
  search
}: PerformanceTileProps) {
  const theme = useMantineTheme();

  const blockTimeColor = [
  theme.other.scoreColors.high,
  theme.other.scoreColors.medium,
  theme.other.scoreColors.low,
  theme.other.scoreColors.critical];


  return (
    <LabeledCard icon={<IconCpu size="1rem" />} label="Performance" anchor="performance">
      <TiledGrid style={{ borderBottom: '0.5px solid var(--observatory-color-border)' }}>
        <TiledGrid.Tile
          span={{
            base: 12,
            md: 9
          }}
          order={{
            base: 2,
            md: 1
          }}>

          <Stack justify="space-between" gap="md" h="100%">
            <Group gap={5}>
              <Text size="sm" lineClamp={1} className={classes.label}>
                Validator Signing Performance
              </Text>
              {periodBadge}
            </Group>

            <BarChart
              mih={120}
              yAxisProps={{
                domain: [0.8, 1],
                hide: false,
                tickFormatter: (val) => NumberFormat.percentage(val) ?? ''
              }}
              data={validators}
              search={search}
              valueKeys={['ratio']}
              cellColor={(value) => {
                if (value >= 0.95) {
                  return theme.other.scoreColors.high;
                } else if (value >= 0.9) {
                  return theme.other.scoreColors.medium;
                } else if (value >= 0.85) {
                  return theme.other.scoreColors.low;
                }
                return theme.other.scoreColors.critical;
              }}
              tooltip={({ label, ratio, missedBlocksCount, totalBlocksCount }) =>
              <Card withBorder p="xs">
                  <Text size="sm" className={classes.value}>
                    {label}
                  </Text>
                  <Text size="xs" className={classes.label}>
                    <span>Missed blocks: </span>
                    <strong>
                      {NumberFormat.decimal(missedBlocksCount)}/{NumberFormat.decimal(totalBlocksCount)}
                    </strong>
                  </Text>
                  <Text size="xs" className={classes.label}>
                    <span>Signing performance: </span>
                    <strong>{NumberFormat.fractionalPercentage(ratio)}</strong>
                  </Text>
                </Card>} />


          </Stack>
        </TiledGrid.Tile>
        <TiledGrid.LabeledTile
          span={{
            base: 12,
            md: 3
          }}
          order={{
            base: 1,
            md: 2
          }}
          label={
          <Group gap={5}>
              <span>Performance Score</span>
              <SyncBadge syncedAt={syncedAt} />
            </Group>}

          tooltip="Score is calculated from signing performance each sync. Validators who frequently miss blocks are decreasing the performance score.">

          <ScoreRing py="md" score={score} labelProps={{ mt: 'sm' }} />
        </TiledGrid.LabeledTile>
      </TiledGrid>

      <TiledGrid>
        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Signed Blocks Window</span>
              <ChainParamsBadge />
            </Group>}

          value={NumberFormat.decimal(signedBlocksWindow)} />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Avg Signing Performance</span>
              {periodBadge}
            </Group>}

          value={NumberFormat.fractionalPercentage(signingPerformance.avg)} />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Lowest Signing Performance</span>
              {periodBadge}
            </Group>}

          value={NumberFormat.fractionalPercentage(signingPerformance.min)} />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Validator Jails</span>
              {periodBadge}
            </Group>}

          value={NumberFormat.decimal(jailsCount)}
          tooltip="Number of detected jailings across all validators in selected time period. This number represents jail occurences and therefore is not necessarily equal to the number of jailed validators." />


        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Avg Block Time</span>
              {periodBadge}
            </Group>}

          value={typeof avgBlockTime === 'number' ? NumberFormat.seconds(avgBlockTime) : 'N/A'} />


        <TiledGrid.Tile
          span={{
            base: 12,
            md: 9
          }}>

          <Stack h="100%" gap={0} justify="space-between">
            <Group gap={5}>
              <Text size="sm" lineClamp={1} className={classes.label}>
                Block Time Distribution
              </Text>
              {periodBadge}
            </Group>

            <Stack gap={5}>
              <Group justify="center">
                {blockTimeDistribution.map(({ label }, index) =>
                <Group key={label} gap={5}>
                    <Box
                    w={20}
                    h={10}
                    style={{
                      borderRadius: 5,
                      backgroundColor: blockTimeColor[index]
                    }} />

                    <Text size="xs">{label}</Text>
                  </Group>
                )}
              </Group>

              <ProgressWithSharedTooltip
                sections={blockTimeDistribution.
                map((distribution, index) => ({ ...distribution, color: blockTimeColor[index] ?? 'bg-1' })).
                filter(({ ratio }) => ratio > 0).
                map(({ ratio, label, count, color }) => {
                  return {
                    id: label,
                    value: ratio * 100,
                    tooltip: (active) =>
                    <Group key={label} gap={5}>
                          <Box bg={color} className={classes.dot} />
                          <Text size="sm" fw={active ? 700 : 400}>{`${label}: ${NumberFormat.decimal(
                          count
                        )} (${NumberFormat.fractionalPercentage(ratio)})`}</Text>
                        </Group>,

                    label: `${NumberFormat.fractionalPercentage(ratio)}`,
                    color
                  };
                })} />

            </Stack>
          </Stack>
        </TiledGrid.Tile>
      </TiledGrid>
    </LabeledCard>);

}