import classes from './index.module.css';

import { Badge, Button, Group, Stack } from '@mantine/core';
import { IconExternalLink, IconFocus2 } from '@tabler/icons-react';
import cx from 'clsx';
import { Fragment } from 'react';

import { LabeledCard } from '~/components/LabeledCard';
import { ScoreRing } from '~/components/ScoreRing';
import { SyncBadge } from '~/components/SyncBadge';
import { TiledGrid } from '~/components/TiledGrid/TiledGrid';
import { Link } from '~/features/links';
import { NumberFormat } from '~/utils/NumberFormat';

import type { DashboardData } from '../../model/types';
import { ConcentrationBar } from './ConcentrationBar';
import { NakamotoBadge } from './NakamotoBadge';

interface DecentralizationTileProps {
  data: DashboardData['decentralization'];
  syncedAt: Date;
}

export function DecentralizationTile({ data, syncedAt }: DecentralizationTileProps) {
  const { countryScore, ispScore, ...concentrations } = data;

  return (
    <LabeledCard icon={<IconFocus2 size="1rem" />} label="Decentralization" anchor="decentralization">
      <TiledGrid style={{ borderBottom: '0.5px solid var(--observatory-color-border)' }}>
        <TiledGrid.LabeledTile
          span={{
            base: 12,
            md: 6
          }}
          label={
          <Group gap={5}>
              <span>Country Score</span>
              <SyncBadge syncedAt={syncedAt} />
            </Group>}

          tooltip="Score is calculated from stake concentrations. High stake concentrations in one country are decreasing the country score.">

          <ScoreRing py="md" score={countryScore} labelProps={{ mt: 'sm' }} />
        </TiledGrid.LabeledTile>
        <TiledGrid.LabeledTile
          span={{
            base: 12,
            md: 6
          }}
          label={
          <Group gap={5}>
              <span>ISP Score</span>
              <SyncBadge syncedAt={syncedAt} />
            </Group>}

          tooltip="Score is calculated from stake concentrations. High stake concentrations in one ISP are decreasing the ISP score.">

          <ScoreRing py="md" score={ispScore} labelProps={{ mt: 'sm' }} />
        </TiledGrid.LabeledTile>
      </TiledGrid>
      <div className={classes.relative}>
        <div className={cx(classes.breakpointLine, classes.globalBreakpointLine)} />
        <Badge variant="light" radius="xs" className={cx(classes.breakpointText, classes.globalBreakpointText)}>
          {NumberFormat.fractionalPercentage(1 / 3)}
        </Badge>
        <TiledGrid>
          {Object.values(concentrations).map(({ label, nakamoto, stake, link }) =>
          <Fragment key={label}>
              <TiledGrid.Tile
              span={{
                base: 12,
                md: 3
              }}>

                <NakamotoBadge p={0} label={label} nakamoto={nakamoto} />
              </TiledGrid.Tile>
              <TiledGrid.Tile
              span={{
                base: 12,
                md: 9
              }}>

                <Stack justify="space-between" className={cx(classes.relative, classes.fullHeight)}>
                  <div className={cx(classes.breakpointLine, classes.tileBreakpointLine)} />
                  <Badge variant="light" radius="xs" className={cx(classes.breakpointText, classes.tileBreakpointText)}>
                    {NumberFormat.fractionalPercentage(1 / 3)}
                  </Badge>
                  <Group justify="flex-end">
                    <Button
                    component={Link}
                    to={link}
                    leftSection={<IconExternalLink size="0.875rem" />}
                    variant="subtle"
                    color="gray"
                    size="xs"
                    className={classes.expandBtn}>

                      Expand
                    </Button>
                  </Group>
                  <ConcentrationBar data={stake} />
                </Stack>
              </TiledGrid.Tile>
            </Fragment>
          )}
        </TiledGrid>
      </div>
    </LabeledCard>);

}