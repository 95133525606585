import type {MetaFunction} from '@remix-run/node';

import {generateMetaTags} from '~/features/seo/utils/generateMetaTags';
import type {loader as zoneSlugLayoutRouteLoader} from '~/routes/$zoneSlug/route';

import type {loader} from './route.loader.server';

export const meta: MetaFunction<typeof loader, {'routes/$zoneSlug': typeof zoneSlugLayoutRouteLoader}> = ({
  data,
  matches,
  params,
}) => {
  const zoneName = matches.find(match => match.id === 'routes/$zoneSlug')?.data.json.zone.name;
  const zoneSlug = data?.json.zoneSlug;
  const keywords = [
    'cosmos zone',
    'validator research',
    'blockchain',
    'blockchain validator',
    'nodes',
    'decentralization',
    'Internet server provider',
    'liquid staking',
    'validator details',
    'validator analysis',
  ];

  if (!zoneName || !zoneSlug) {
    return generateMetaTags({
      title: `Blockchain ${params.zoneSlug ? `${params.zoneSlug} ` : ''}not found`,
    });
  }

  return generateMetaTags({
    title: zoneName,
    description: `Analyse ${zoneName} and find out health score based on key details such as country, ISP, block signing and more.`,
    image: `/og/${zoneSlug}`,
    keywords,
  });
};
