import {Badge, Tooltip} from '@mantine/core';
import type {BadgeProps} from '@mantine/core';

interface ChainParamsBadgeProps extends BadgeProps {}

export function ChainParamsBadge({...props}: ChainParamsBadgeProps) {
  return (
    <Tooltip label="Value obtained from chain params">
      <Badge variant="light" size="xs" color="gray" {...props}>
        Chain Params
      </Badge>
    </Tooltip>
  );
}
