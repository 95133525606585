/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PoolsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Pools All
     * @param lowLiquidity
     * @returns any Successful Response
     * @throws ApiError
     */
    public poolsAll(
        lowLiquidity: boolean,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pools/v2/all',
            query: {
                'low_liquidity': lowLiquidity,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Pool By Id
     * - **pool_id**: ID of pool to return
     * @param poolId
     * @returns any Successful Response
     * @throws ApiError
     */
    public poolById(
        poolId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pools/v2/{pool_id}',
            path: {
                'pool_id': poolId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Liquidity By Pools
     * @param poolId
     * @returns any Successful Response
     * @throws ApiError
     */
    public liquidityByPools(
        poolId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pools/v2/liquidity/{pool_id}/chart',
            path: {
                'pool_id': poolId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Volume By Pools
     * @param poolId
     * @returns any Successful Response
     * @throws ApiError
     */
    public volumeByPools(
        poolId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pools/v2/volume/{pool_id}/chart',
            path: {
                'pool_id': poolId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
