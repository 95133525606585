/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StreamService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Pool All Stream
     * @param minLiquidity
     * @param orderKey
     * @param orderBy
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public poolAllStream(
        minLiquidity: number = 1,
        orderKey: string = 'liquidity',
        orderBy: string = 'desc',
        offset?: number,
        limit: number = 100,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stream/pool/v1/all',
            query: {
                'min_liquidity': minLiquidity,
                'order_key': orderKey,
                'order_by': orderBy,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Pool Id Stream
     * @param poolId
     * @returns any Successful Response
     * @throws ApiError
     */
    public poolIdStream(
        poolId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stream/pool/v1/{pool_id}',
            path: {
                'pool_id': poolId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Token All Stream
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokenAllStream(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stream/token/v1/all',
        });
    }

    /**
     * Token Symbol Stream
     * @param symbol
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokenSymbolStream(
        symbol: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stream/token/v1/symbol',
            query: {
                'symbol': symbol,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Token Denom Stream
     * @param denom
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokenDenomStream(
        denom: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stream/token/v1/denom',
            query: {
                'denom': denom,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
