import classes from './NakamotoBadge.module.css';

import { Box, type BoxProps, Group, Text, rem } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import cx from 'clsx';

import { Heat } from './Heat';

interface NakamotoBadgeProps extends BoxProps {
  label: string;
  nakamoto: number;
}

export function NakamotoBadge({ label, nakamoto, className, ...others }: NakamotoBadgeProps) {
  const trueNakamoto = Math.ceil(nakamoto);

  return (
    <Box className={cx(classes.root, className)} {...others}>
      <Group justify="space-between" wrap="nowrap">
        <div>
          <Text size="lg" className={classes.label} lineClamp={2} fw={500}>
            {label}
          </Text>
          <Text className={classes['nakamoto-label']} size="xs">
            Nakamoto coefficient
          </Text>
        </div>
        <Text size={rem(40)} fw={600} className={classes['nakamoto-value']} ff="var(--observatory-font-family-number)">
          {trueNakamoto}
        </Text>
      </Group>
      <Group wrap="nowrap" gap={2} className={classes['heat-line']}>
        <Group gap={2} wrap="nowrap" className={cx(classes['tendency-badge'], classes['bg-red'])}>
          <Text size="xs" className={classes['tendency-label']}>
            {trueNakamoto - 1}
          </Text>
          <IconChevronDown stroke={3} size="0.875rem" />
        </Group>

        <Heat value={nakamoto % 1 * 100} />

        <Group gap={2} wrap="nowrap" className={cx(classes['tendency-badge'], classes['bg-green'])}>
          <IconChevronUp stroke={3} size="0.875rem" />
          <Text size="xs" className={classes['tendency-label']}>
            {trueNakamoto + 1}
          </Text>
        </Group>
      </Group>
    </Box>);

}