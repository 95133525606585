import {SegmentedControl} from '@mantine/core';
import type {SegmentedControlProps} from '@mantine/core';
import {useSearchParams} from '@remix-run/react';

import {
  DASHBOARD_DEFAULT_DURATION_PERIOD,
  DASHBOARD_DURATION_PARAM_KEY,
  DASHBOARD_DURATION_PERIODS,
} from '../route.constants';

interface DurationParamControlProps extends Omit<SegmentedControlProps, 'value' | 'data' | 'onChange'> {}

export function DurationParamControl(props: DurationParamControlProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePeriodSelect = (value: string) => {
    searchParams.set(DASHBOARD_DURATION_PARAM_KEY, value);
    setSearchParams(searchParams);
  };

  return (
    <SegmentedControl
      orientation="vertical"
      size="sm"
      value={searchParams.get(DASHBOARD_DURATION_PARAM_KEY) ?? DASHBOARD_DEFAULT_DURATION_PERIOD.value}
      data={DASHBOARD_DURATION_PERIODS}
      onChange={handlePeriodSelect}
      {...props}
    />
  );
}
