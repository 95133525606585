/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IbcService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Ibc Info
     * List all existing paths that have been manually set up on the config
     * @returns any Successful Response
     * @throws ApiError
     */
    public ibcInfo(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ibc/v1/info',
        });
    }

    /**
     * Ibc All
     * List all existing paths with pending packets, last tx and since when (in minutes)
     *
     * optionnal: filter by dex
     * @param dex
     * @returns any Successful Response
     * @throws ApiError
     */
    public ibcAll(
        dex?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ibc/v1/all',
            query: {
                'dex': dex,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Ibc Source Destination
     * List chosen source and destination paths with pending packets, last tx and since when (in minutes) strictly superior to trigger option
     * @param source
     * @param destination
     * @param minutesTrigger
     * @returns any Successful Response
     * @throws ApiError
     */
    public ibcSourceDestination(
        source: string,
        destination: string,
        minutesTrigger: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ibc/v1/source/{source}/destination{destination}',
            path: {
                'source': source,
                'destination': destination,
            },
            query: {
                'minutes_trigger': minutesTrigger,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Ibc Source
     * List chosen source paths with pending packets, last tx and since when (in minutes) strictly superior to trigger option
     * @param source
     * @param minutesTrigger
     * @returns any Successful Response
     * @throws ApiError
     */
    public ibcSource(
        source: string,
        minutesTrigger: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ibc/v1/source/{source}',
            path: {
                'source': source,
            },
            query: {
                'minutes_trigger': minutesTrigger,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Ibc Destination
     * @param destination
     * @param minutesTrigger
     * @returns any Successful Response
     * @throws ApiError
     */
    public ibcDestination(
        destination: string,
        minutesTrigger: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ibc/v1/destination/{destination}',
            path: {
                'destination': destination,
            },
            query: {
                'minutes_trigger': minutesTrigger,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Ibc Raw
     * @returns any Successful Response
     * @throws ApiError
     */
    public ibcRaw(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ibc/v1/raw',
        });
    }

}
