import classes from './Heat.module.css';

import cx from 'clsx';
import { type ComponentPropsWithoutRef } from 'react';

interface HeatProps extends ComponentPropsWithoutRef<'div'> {
  value: number;
}

export function Heat({ value, className, ...others }: HeatProps) {
  return (
    <div className={cx(classes.root, className)} {...others}>
      <div className={classes.remainder} style={{ width: `${100 - value}%` }}>
        <div className={classes['center-line']} />
      </div>
    </div>);

}