/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AprService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Apr Staking Osmo
     * @returns any Successful Response
     * @throws ApiError
     */
    public aprStakingOsmo(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/apr/v2/staking',
        });
    }

    /**
     * Apr All
     * @returns any Successful Response
     * @throws ApiError
     */
    public aprAll(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/apr/v2/all',
        });
    }

    /**
     * Apr Per Pool Id
     * @param poolId
     * @returns any Successful Response
     * @throws ApiError
     */
    public aprPerPoolId(
        poolId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/apr/v2/{pool_id}',
            path: {
                'pool_id': poolId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
