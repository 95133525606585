/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SearchService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * Search Amount Total Supply Osmo
     * @returns any Successful Response
     * @throws ApiError
     */
    public searchAmountTotalSupplyOsmo(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/v1/supply/osmo',
        });
    }

    /**
     * Search Denom By Given Symbol
     * - **symbol**: symbol to return in denom
     * @param symbol
     * @returns any Successful Response
     * @throws ApiError
     */
    public searchDenomByGivenSymbol(
        symbol: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/v1/denom',
            query: {
                'symbol': symbol,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Symbol By Given Denom
     * - **denom**: denom to return in symbol
     * @param denom
     * @returns any Successful Response
     * @throws ApiError
     */
    public searchSymbolByGivenDenom(
        denom: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/v1/symbol',
            query: {
                'denom': denom,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Exponent By Given Symbol
     * - **symbol**: symbol to return is exponent
     * @param symbol
     * @returns any Successful Response
     * @throws ApiError
     */
    public searchExponentByGivenSymbol(
        symbol: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/v1/exponent',
            query: {
                'symbol': symbol,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
