import classes from './RevenueTile.module.css';

import { Alert, Card, Center, Group, Overlay, Stack, Text, getGradient, rgba, useMantineTheme } from '@mantine/core';
import { IconCoin } from '@tabler/icons-react';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { OsmosisClient } from '@rockawayxlabs/osmosis-sdk';

import { BarChart } from '~/components/BarChart';
import { ChainParamsBadge } from '~/components/ChainParamsBadge';
import { LabeledCard } from '~/components/LabeledCard';
import { TiledGrid } from '~/components/TiledGrid/TiledGrid';
import { NumberFormat } from '~/utils/NumberFormat';

import type { DashboardData } from '../model/types';

interface RevenueTileProps {
  data: DashboardData['revenue'];
  tokenSymbol: string;
  periodBadge: ReactNode;
  search: string;
}

export function RevenueTile({
  data: { cost, enabled, baseApr, commissions, revenues, validators },
  tokenSymbol,
  periodBadge,
  search
}: RevenueTileProps) {
  const theme = useMantineTheme();
  const [tokenPriceUsd, setTokenPriceUsd] = useState<number>();

  useEffect(() => {
    new OsmosisClient({ BASE: 'https://api-osmosis.imperator.co' }).tokens.
    tokensPrice(tokenSymbol).
    then(({ price }) => {
      if (typeof price === 'number') {
        setTokenPriceUsd(price);
      }
    }).
    catch();
  }, [tokenSymbol]);

  return (
    <LabeledCard
      icon={<IconCoin size="1rem" />}
      label="Validator Revenue"
      anchor="validator-revenue"
      classNames={{ body: classes.relative }}>

      {!enabled &&
      <>
          <Overlay opacity={0.95} zIndex={1} style={{ '--overlay-bg': 'var(--observatory-color-bg-2)' }} />
          <Center
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 2
          }}>

            <Alert
            style={{
              border: 'none',
              marginTop: 1,
              background: getGradient({ from: '#ee8159', to: '#f9d673', deg: 90 }, theme)
            }}>

              <Text span size="sm" fw={700} c="white">
                Coming soon.
              </Text>{' '}
              <Text span size="sm" fw={500} c="white">
                We are not able to detect validator revenue on this chain yet.
              </Text>
            </Alert>
          </Center>
        </>}

      <TiledGrid style={{ borderBottom: '0.5px solid var(--observatory-color-border)' }}>
        <TiledGrid.Tile
          span={{
            base: 12,
            md: 9
          }}
          order={{
            base: 2,
            md: 1
          }}>

          <Stack justify="space-between" gap="md" h="100%">
            <Group gap={5}>
              <Text size="sm" lineClamp={1} className={classes.label}>
                Est. Validator Revenue From Inflation
              </Text>
              {periodBadge}
            </Group>

            <BarChart
              mih={130}
              yAxisProps={{
                tickFormatter: (value) => NumberFormat.tokenAmount(value, tokenSymbol) ?? '',
                hide: false,
                width: 50
              }}
              data={validators}
              search={search}
              cellColor={(value) => {
                const ratio = Math.min(value / (revenues.max || 1), 1);
                const alpha = 0.75 + ratio * 0.25;
                return rgba(theme.other.scoreColors.high, alpha);
              }}
              tooltip={({ label, value, commissions, tokens }) =>
              <Card withBorder p="xs">
                  <Text size="sm" className={classes.value}>
                    {label}
                  </Text>

                  <Group justify="space-between" align="flex-start">
                    <div>
                      <Text size="xs" className={classes.label}>
                        Est. revenue:
                      </Text>
                      <Text size="xs" className={classes.label}>
                        <strong>{NumberFormat.tokenAmount(value, tokenSymbol)}</strong>
                        {typeof tokenPriceUsd === 'number' &&
                      <strong> (${NumberFormat.float(tokenPriceUsd * value)})</strong>}

                      </Text>
                    </div>
                    <div>
                      <Text size="xs" className={classes.label}>
                        Commission:
                      </Text>
                      <Group gap={0} style={{ columnGap: theme.spacing.md }}>
                        {commissions.min === commissions.max ?
                      <Text size="xs" className={classes.label}>
                            <strong>{NumberFormat.fractionalPercentage(commissions.min)}</strong>
                          </Text> :

                      <>
                            <Text size="xs" className={classes.label}>
                              <span>Min:</span>
                              <br />
                              <strong>{NumberFormat.fractionalPercentage(commissions.min)}</strong>
                            </Text>
                            <Text size="xs" className={classes.label}>
                              <span>Max:</span>
                              <br />
                              <strong>{NumberFormat.fractionalPercentage(commissions.max)}</strong>
                            </Text>
                            <Text size="xs" className={classes.label}>
                              <span>Avg:</span>
                              <br />
                              <strong>{NumberFormat.fractionalPercentage(commissions.avg)}</strong>
                            </Text>
                          </>}

                      </Group>
                    </div>
                  </Group>

                  <Text size="xs" mt={5} className={classes.label}>
                    Tokens
                  </Text>
                  <Group gap={0} style={{ columnGap: theme.spacing.md }}>
                    <Text size="xs" className={classes.label}>
                      <span>Min:</span>
                      <br />
                      <strong>{NumberFormat.tokenAmount(tokens.min, tokenSymbol)}</strong>
                    </Text>
                    <Text size="xs" className={classes.label}>
                      <span>Max:</span>
                      <br />
                      <strong>{NumberFormat.tokenAmount(tokens.max, tokenSymbol)}</strong>
                    </Text>
                    <Text size="xs" className={classes.label}>
                      <span>Avg:</span>
                      <br />
                      <strong>{NumberFormat.tokenAmount(tokens.avg, tokenSymbol)}</strong>
                    </Text>
                  </Group>
                </Card>} />


          </Stack>
        </TiledGrid.Tile>
        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          order={{
            base: 1,
            md: 2
          }}
          label={
          <Group gap={5}>
              <span>Est. Cost</span>
              {periodBadge}
            </Group>}

          value={
          <>
              {typeof tokenPriceUsd === 'number' &&
            <Text size="md" fw={500} mb={5} pt={5}>
                  ${NumberFormat.float(tokenPriceUsd * cost)}
                </Text>}

              <div>{NumberFormat.tokenAmount(cost, tokenSymbol)}</div>
            </>}

          tooltip="Sum of estimated revenues across all validators. Estimated validator revenues are calculated by considering the average tokens, commission rate, APR, and selected time period." />

      </TiledGrid>

      <TiledGrid>
        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Avg Est. Revenue</span>
              {periodBadge}
            </Group>}

          value={
          <>
              {typeof tokenPriceUsd === 'number' &&
            <Text size="md" fw={500} pt={5}>
                  ${NumberFormat.float(tokenPriceUsd * +revenues.avg)}
                </Text>}

              <div>{NumberFormat.tokenAmount(revenues.avg, tokenSymbol)}</div>
            </>}

          tooltip="Average estimated revenue across all validators. Estimated validator revenues are calculated by considering the average tokens, commission rate, APR, and selected time period." />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Avg Commission</span>
              {periodBadge}
            </Group>}

          value={NumberFormat.fractionalPercentage(commissions.avg)} />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label="Token Price USD"
          value={typeof tokenPriceUsd === 'number' ? `$${NumberFormat.float(tokenPriceUsd)}` : 'N/A'} />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Base APR</span>
              <ChainParamsBadge />
            </Group>}

          value={typeof baseApr === 'number' ? NumberFormat.fractionalPercentage(baseApr) : 'N/A'}
          tooltip="Determined by annual provisions from mint module and community tax from distribution params." />

      </TiledGrid>
    </LabeledCard>);

}