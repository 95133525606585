/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AprService } from './services/AprService';
import { FeesService } from './services/FeesService';
import { HealthService } from './services/HealthService';
import { IbcService } from './services/IbcService';
import { LiquidityService } from './services/LiquidityService';
import { OverviewService } from './services/OverviewService';
import { PairsService } from './services/PairsService';
import { PoolsService } from './services/PoolsService';
import { SearchService } from './services/SearchService';
import { StreamService } from './services/StreamService';
import { SupplyService } from './services/SupplyService';
import { TokensService } from './services/TokensService';
import { TradingviewService } from './services/TradingviewService';
import { VolumeService } from './services/VolumeService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class OsmosisClient {

    public readonly apr: AprService;
    public readonly fees: FeesService;
    public readonly health: HealthService;
    public readonly ibc: IbcService;
    public readonly liquidity: LiquidityService;
    public readonly overview: OverviewService;
    public readonly pairs: PairsService;
    public readonly pools: PoolsService;
    public readonly search: SearchService;
    public readonly stream: StreamService;
    public readonly supply: SupplyService;
    public readonly tokens: TokensService;
    public readonly tradingview: TradingviewService;
    public readonly volume: VolumeService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.1.1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.apr = new AprService(this.request);
        this.fees = new FeesService(this.request);
        this.health = new HealthService(this.request);
        this.ibc = new IbcService(this.request);
        this.liquidity = new LiquidityService(this.request);
        this.overview = new OverviewService(this.request);
        this.pairs = new PairsService(this.request);
        this.pools = new PoolsService(this.request);
        this.search = new SearchService(this.request);
        this.stream = new StreamService(this.request);
        this.supply = new SupplyService(this.request);
        this.tokens = new TokensService(this.request);
        this.tradingview = new TradingviewService(this.request);
        this.volume = new VolumeService(this.request);
    }
}

