/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PairsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Pairs Summary
     * @returns any Successful Response
     * @throws ApiError
     */
    public pairsSummary(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pairs/v1/summary',
        });
    }

    /**
     * Pairs Historical Chart
     * - **pool_id**: ID of pool to return
     * - **asset_in**: First name asset of PAIR (ex for ATOM/USDT, asset_out=ATOM (symbol) or asset_out=ibc/1XX (denom))
     * - **asset_out**: Second name asset of PAIR (ex for ATOM/USDT, asset_out=USDT (symbol) or asset_out=ibc/2XX (denom))
     * - **range**: Range of historical Data (Available values : 7d, 1mo, 1y, all)
     * - **asset_type**: Type of asset in asset_in and asset_out (Available values : symbol, denom)
     * @param poolId
     * @param assetIn
     * @param assetOut
     * @param range
     * @param assetType
     * @returns any Successful Response
     * @throws ApiError
     */
    public pairsHistoricalChart(
        poolId: number,
        assetIn: string,
        assetOut: string,
        range: string,
        assetType: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pairs/v1/historical/{pool_id}/chart',
            path: {
                'pool_id': poolId,
            },
            query: {
                'asset_in': assetIn,
                'asset_out': assetOut,
                'range': range,
                'asset_type': assetType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
