import classes from './NewsfeedTile.module.css';

import { Button } from '@mantine/core';
import { IconExternalLink, IconTimeline } from '@tabler/icons-react';

import { LinkBuilder } from '@rockawayxlabs/observatory-utils';

import { LabeledCard } from '~/components/LabeledCard';
import { Link } from '~/features/links';
import { Newsfeed } from '~/features/newsfeed';
import type { NewsfeedZone } from '~/features/newsfeed/types';

interface NewsfeedTileProps {
  zone: NewsfeedZone;
}

export function NewsfeedTile({ zone }: NewsfeedTileProps) {
  return (
    <LabeledCard
      icon={<IconTimeline size="1rem" />}
      label="Newsfeed"
      anchor="newsfeed"
      actions={
      <Button
        component={Link}
        to={LinkBuilder.zone(zone.slug).newsfeed().toString()}
        leftSection={<IconExternalLink size="0.875rem" />}
        variant="subtle"
        color="gray"
        size="xs"
        className={classes.expandBtn}>

          Expand
        </Button>}>


      <Newsfeed px="xl" py="md" zone={zone} maxHeight={300} />
    </LabeledCard>);

}