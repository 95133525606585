export const DASHBOARD_DATA_VERSION = 4 as const; // TODO increment when changing the data structure of route model

export const DASHBOARD_DEFAULT_DURATION_PERIOD = {label: '90 days', value: 'P90D'};
export const DASHBOARD_DURATION_PERIODS = [
  {label: '1 day', value: 'P1D'},
  {label: '7 days', value: 'P7D'},
  {label: '30 days', value: 'P30D'},
  DASHBOARD_DEFAULT_DURATION_PERIOD,
];
export const DASHBOARD_DURATION_PARAM_KEY = 'period';

export const DISABLED_REVENUE_ZONES = [
  'axelar',
  'celestia',
  'celestia-testnet',
  'crescent',
  'cronos',
  'dydx',
  'dydx-testnet',
  'evmos',
  'juno-network',
  'kujira',
  'mars-hub',
  'osmosis',
  'polygon',
  'quicksilver',
  'sommelier',
  'stride',
  'terra-classic',
];

export const NONINFLATIONARY_ZONES = ['provenance', 'dydx'];
