import classes from './index.module.css';

import { Alert, Badge, Box, Button, Card, Group, Stack, Text } from '@mantine/core';
import { captureMessage } from '@sentry/remix';
import { IconAlertTriangle, IconExternalLink, IconMoneybag, IconRotateClockwise } from '@tabler/icons-react';
import cx from 'clsx';
import { useState } from 'react';

import { LinkBuilder } from '@rockawayxlabs/observatory-utils';

import { BarChart } from '~/components/BarChart';
import { LabeledCard } from '~/components/LabeledCard';
import { TiledGrid } from '~/components/TiledGrid/TiledGrid';
import { Link } from '~/features/links';
import { NumberFormat } from '~/utils/NumberFormat';
import { createCompareFnFromArrayOrder } from '~/utils/compareFn';

import type { DashboardData } from '../../model/types';
import { fetchSkipValidatorPerformance } from '../../utils/fetchSkipValidatorPerformance';

interface Props {
  data: NonNullable<DashboardData['mevDiscrepancy']>;
  zoneSlug: string;
  tokenSymbol: string;
  search: string;
}

export function MevDiscrepancyTile({ data, zoneSlug, tokenSymbol, search }: Props) {
  const [validators, setValidators] = useState(data.validators);
  const [allowRefetch, setAllowRefetch] = useState(data.allowRefetch);
  const [isRefetching, setIsRefetching] = useState(false);

  const categories = new Map([
  [(
  'RED' as const),
  {
    label: 'Potential MEV Extraction',
    color: 'var(--observatory-color-score-critical)'
  }],

  [(
  'YELLOW' as const),
  {
    label: 'Elevated Discrepancy',
    color: 'var(--observatory-color-score-medium)'
  }],

  [(
  'GREEN' as const),
  {
    label: 'Nominal Discrepancy',
    color: 'var(--observatory-color-score-high)'
  }],

  [(
  'UNKNOWN' as const),
  {
    label: 'Unknown',
    color: 'var(--mantine-color-gray-5)'
  }]]

  );

  const handleRefetch = async () => {
    try {
      setIsRefetching(true);
      const validatorPerformance = await fetchSkipValidatorPerformance();

      // we are relaying on the order for the parsing from Skip's response
      const performanceCategories = (['UNKNOWN', 'GREEN', 'YELLOW', 'RED'] as const);
      // we need different order for the sorting function
      const sortedPerformanceCategories = (['GREEN', 'YELLOW', 'RED', 'UNKNOWN'] as const);
      const validatorCompareFn = createCompareFnFromArrayOrder(sortedPerformanceCategories);

      const validatorPerformanceMap = new Map(
        validatorPerformance.map(({ operatorAddress, category }) => [operatorAddress, performanceCategories[+category]])
      );

      setValidators((prevValidators) => {
        const updateValidators = prevValidators.
        map((validator) => ({
          ...validator,
          performance: validatorPerformanceMap.get(validator.operatorAddress) ?? 'UNKNOWN'
        })).
        sort((a, b) => validatorCompareFn(a.performance, b.performance) || b.tokens - a.tokens);

        return updateValidators;
      });
      setAllowRefetch(false);
    } catch (error) {
      captureMessage('Failed to fetch performance data from client fallback', { extra: { error } });
    } finally {
      setIsRefetching(false);
    }
  };

  return (
    <LabeledCard
      icon={<IconMoneybag size={16} />}
      label="MEV Discrepancies"
      anchor="mev-discrepancies"
      classNames={{ body: classes.relative }}
      actions={
      <Button
        component={Link}
        to={LinkBuilder.zone(zoneSlug).mev().toString()}
        leftSection={<IconExternalLink size="0.875rem" className={classes.expandBtnIcon} />}
        variant="subtle"
        color="gray"
        size="xs"
        className={classes.expandBtn}>

          Expand
        </Button>}>


      {allowRefetch &&
      <Alert
        color="red"
        title={
        <Group justify="space-between" w="100%">
              <span>Failed to fetch performance data</span>
              <Button
            size="xs"
            color="red"
            variant="outline"
            leftSection={<IconRotateClockwise size="0.875rem" />}
            loading={isRefetching}
            onClick={handleRefetch}>

                Refetch
              </Button>
            </Group>}

        icon={<IconAlertTriangle size="1rem" />}
        classNames={{ label: classes.alertLabel, wrapper: classes.alertWrapper }} />}


      {validators.length > 0 &&
      <TiledGrid>
          <TiledGrid.Tile span={12}>
            <Stack justify="space-between" gap="md" h="100%">
              <Group justify="space-between">
                <Group gap={5}>
                  <Text size="sm" lineClamp={1} className={classes.label}>
                    Validator Set Discrepancies
                  </Text>
                  <Badge size="xs" color="gray" variant="light">
                    Last 5 000 blocks
                  </Badge>
                </Group>

                <Group gap="xs">
                  {[...categories.values()].map(({ label, color }) =>
                <Group gap={5} key={label}>
                      <Box w={10} h={10} bg={color} />
                      <Text size="xs" lineClamp={1} className={classes.label}>
                        {label}
                      </Text>
                    </Group>
                )}
                </Group>
              </Group>
              <BarChart
              mih={120}
              pl={20}
              yAxisProps={{
                dataKey: 'tokens',
                hide: false,
                tickFormatter: (val) => NumberFormat.tokenAmount(val, tokenSymbol) ?? ''
              }}
              data={validators}
              search={search}
              valueKeys={['tokens']}
              cellColor={(_, __, cell) => categories.get(cell.performance)?.color ?? 'transparent'}
              tooltip={({ label, performance, tokens }) => {
                const category = categories.get(performance);

                return (
                  <Card withBorder p="xs">
                      <Text size="sm" className={classes.value}>
                        {label}
                      </Text>

                      <Group gap={5}>
                        <Box w={10} h={10} bg={category?.color} />
                        <Text size="xs" lineClamp={1} className={classes.label}>
                          {category?.label}
                        </Text>
                      </Group>

                      <Text size="xs" className={classes.label}>
                        <span>Tokens: </span>
                        <strong>{NumberFormat.tokenAmount(tokens, tokenSymbol)}</strong>
                      </Text>
                    </Card>);

              }}
              overlay={
              validators.length <= 0 &&
              <div className={classes.overlay}>
                      <Text size="xs" tt="uppercase" className={cx(classes.overlayContent, classes.value)}>
                        Missing MEV Performance Data
                      </Text>
                    </div>} />



            </Stack>
          </TiledGrid.Tile>
        </TiledGrid>}

    </LabeledCard>);

}