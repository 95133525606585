import classes from './GovernanceTile.module.css';

import { Carousel } from '@mantine/carousel';
import { Box, Button, Card, Group, Stack, Text, Tooltip, rgba, useMantineTheme } from '@mantine/core';
import { IconAbacus, IconAlertTriangle, IconExternalLink } from '@tabler/icons-react';
import cx from 'clsx';
import type { ReactNode } from 'react';

import { LinkBuilder } from '@rockawayxlabs/observatory-utils';

import { BarChart } from '~/components/BarChart';
import { DateTime } from '~/components/DateTime';
import { GovernanceProposalStatusBadge } from '~/components/GovernanceProposalStatusBadge';
import { LabeledCard } from '~/components/LabeledCard';
import { ProgressWithSharedTooltip } from '~/components/ProgressWithSharedTooltip/ProgressWithSharedTooltip';
import { ScoreRing } from '~/components/ScoreRing';
import { SyncBadge } from '~/components/SyncBadge';
import { TallyResult } from '~/components/TallyResult';
import { TiledGrid } from '~/components/TiledGrid/TiledGrid';
import { Link } from '~/features/links';
import { NumberFormat } from '~/utils/NumberFormat';

import type { DashboardData } from '../model/types';

interface GovernanceTileProps {
  data: DashboardData['governance'];
  periodBadge: ReactNode;
  syncedAt: Date;
  zoneSlug: string;
  tokenSymbol: string;
  search: string;
}

export function GovernanceTile({
  data: {
    score,
    avgValidatorParticipation,
    delegatorParticipation,
    missingDelegationsCount,
    nonvotingValidatorsCount,
    activeValidatorsCount,
    recentProposalsCount,
    totalProposalsCount,
    validators,
    proposals
  },
  periodBadge,
  syncedAt,
  zoneSlug,
  tokenSymbol,
  search
}: GovernanceTileProps) {
  const theme = useMantineTheme();

  return (
    <LabeledCard
      icon={<IconAbacus size="1rem" />}
      label="Governance"
      anchor="governance"
      actions={
      <Button
        component={Link}
        to={LinkBuilder.zone(zoneSlug).governance().toString()}
        leftSection={<IconExternalLink size="0.875rem" />}
        variant="subtle"
        color="gray"
        size="xs"
        className={classes.expandBtn}>

          Expand
        </Button>}>


      <TiledGrid style={{ borderBottom: '0.5px solid var(--observatory-color-border)' }}>
        <TiledGrid.Tile
          span={{
            base: 12,
            md: 9
          }}
          order={{
            base: 2,
            md: 1
          }}>

          <Stack justify="space-between" gap="md" h="100%">
            <Group justify="space-between">
              <Group gap={5}>
                <Text size="sm" lineClamp={1} className={classes.label}>
                  Validator Governance
                </Text>
                {periodBadge}
              </Group>

              <Group gap="xs">
                <Group gap={5}>
                  <Box w={10} h={3} bg={theme.other.scoreColors.high} />
                  <Text size="xs" lineClamp={1} className={classes.label}>
                    Participation
                  </Text>
                </Group>
                <Group gap={5}>
                  <Box w={10} h={10} bg={theme.other.scoreColors.critical} />
                  <Text size="xs" lineClamp={1} className={classes.label}>
                    Validator Voting Power
                  </Text>
                </Group>
                <Group gap={5}>
                  <Box w={10} h={10} bg={theme.colors.cyan[4]} />
                  <Text size="xs" lineClamp={1} className={classes.label}>
                    Delegators Voting Power
                  </Text>
                </Group>
              </Group>
            </Group>
            <BarChart
              mih={120}
              yAxisProps={{
                domain: [0, 1],
                hide: false,
                tickFormatter: (val) => NumberFormat.percentage(val) ?? ''
              }}
              data={recentProposalsCount ? validators : []}
              search={search}
              valueKeys={['delegatorsVotingPowerRatio', 'validatorVotingPowerRatio']}
              lineKeys={['participationRatio']}
              cellColor={(value, key) => {
                if (key === 'delegatorsVotingPowerRatio') {
                  return theme.colors.cyan[4];
                }
                const ratio = Math.min(value, 1);
                const alpha = 0.75 + ratio * 0.25;
                return rgba(theme.other.scoreColors.critical, alpha);
              }}
              tooltip={({
                label,
                participationRatio,
                votesCount,
                proposalsCount,
                totalVotingPower,
                validatorVotingPower,
                delegatorsVotingPower
              }) =>
              <Card withBorder p="xs">
                  <Text size="sm" className={classes.value}>
                    {label}
                  </Text>
                  <Text size="xs" className={classes.label}>
                    Was eligible to vote on <strong>{NumberFormat.decimal(proposalsCount)} proposals</strong>
                  </Text>
                  <Text size="xs" className={classes.label}>
                    <span>Voted on </span>
                    <strong>
                      {NumberFormat.decimal(votesCount)}/{NumberFormat.decimal(proposalsCount)} (
                      {NumberFormat.fractionalPercentage(participationRatio)})
                    </strong>
                  </Text>
                  <Text size="xs" mt="xs" className={classes.label}>
                    Total Voting Power <strong>{NumberFormat.decimal(totalVotingPower)}</strong>
                  </Text>
                  <Group gap={0} style={{ columnGap: theme.spacing.md }}>
                    <Text size="xs" className={classes.label}>
                      <span>Validator:</span>
                      <br />
                      {typeof validatorVotingPower === 'number' ?
                    <>
                          <strong>{NumberFormat.decimal(validatorVotingPower)}</strong>
                          {!!totalVotingPower &&
                      <strong>
                              {' '}
                              ({NumberFormat.fractionalPercentage(validatorVotingPower / totalVotingPower)})
                            </strong>}

                        </> :

                    <strong>N/A</strong>}

                    </Text>
                    <Text size="xs" className={classes.label}>
                      <span>Delegators:</span>
                      <br />
                      {typeof delegatorsVotingPower === 'number' ?
                    <>
                          <strong>{NumberFormat.decimal(delegatorsVotingPower)}</strong>
                          {!!totalVotingPower &&
                      <strong>
                              {' '}
                              ({NumberFormat.fractionalPercentage(delegatorsVotingPower / totalVotingPower)})
                            </strong>}

                        </> :

                    <strong>N/A</strong>}

                    </Text>
                  </Group>
                </Card>}

              overlay={
              !recentProposalsCount &&
              <div className={classes.overlay}>
                    <Text size="xs" tt="uppercase" className={cx(classes.overlayContent, classes.value)}>
                      No proposals detected in selected time period
                    </Text>
                  </div>} />



          </Stack>
        </TiledGrid.Tile>
        <TiledGrid.LabeledTile
          span={{
            base: 12,
            md: 3
          }}
          order={{
            base: 1,
            md: 2
          }}
          label={
          <Group gap={5}>
              <span>Governance Score</span>
              <SyncBadge syncedAt={syncedAt} />
            </Group>}

          tooltip="Score is calculated from participation in governance each sync. Validators who are eligible but do not vote are decreasing the governance score.">

          <ScoreRing py="md" score={score} labelProps={{ mt: 'sm' }} />
        </TiledGrid.LabeledTile>
      </TiledGrid>

      <TiledGrid>
        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Avg Participation</span>
              {periodBadge}
            </Group>}

          value={
          typeof avgValidatorParticipation === 'number' ?
          NumberFormat.fractionalPercentage(avgValidatorParticipation) :
          'N/A'}

          tooltip="Validator participation is calculated as the number of votes cast divided by the number of proposals validator is eligible to vote for in the selected time period. The average is then weighted by the validator's voting power." />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Delegator Participation</span>
              {periodBadge}
            </Group>}

          value={
          typeof delegatorParticipation === 'number' ?
          NumberFormat.fractionalPercentage(delegatorParticipation) :
          'N/A'}

          {...missingDelegationsCount && {
            tooltip:
            <div>
                <span>Voting power utilized by delegators in the selected time period.</span>
                <br />
                <strong>
                  {`Missing delegations for ${missingDelegationsCount} validators, delegator participation may be inaccurate.`}
                </strong>
              </div>,

            tooltipIcon: IconAlertTriangle,
            tooltipIconColor: 'orange.4'
          }}
          {...!missingDelegationsCount && {
            tooltip: 'Voting power utilized by delegators in the selected time period.'
          }} />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>Nonvoting Validators</span>
              {periodBadge}
            </Group>}

          value={NumberFormat.decimal(nonvotingValidatorsCount)}
          secondaryValue={NumberFormat.decimal(activeValidatorsCount)}
          tooltip="Validators who were eligible to vote for at least one proposal in the selected time period but did not cast their vote." />

        <TiledGrid.StatTile
          span={{
            base: 12,
            md: 3
          }}
          label={
          <Group gap={5}>
              <span>No. of Proposals</span>
              {periodBadge}
            </Group>}

          value={NumberFormat.decimal(recentProposalsCount)}
          secondaryValue={NumberFormat.decimal(totalProposalsCount)}
          tooltip="Number of proposals created in the selected time period out of all proposals." />

        {proposals.length > 0 &&
        <TiledGrid.Tile span={12} style={{ overflow: 'hidden' }}>
            <Group gap={5} pb="md">
              <Text size="sm" lineClamp={1} className={classes.label}>
                Recent Voting Results
              </Text>
              {periodBadge}
            </Group>
            <Carousel
            dragFree
            withControls={false}
            align="start"
            ta="start"
            slideSize={{
              base: '90%',
              sm: '60%',
              md: '30%'
            }}
            slideGap={{
              base: 'xs',
              md: 'md'
            }}>

              {proposals.map((proposal) =>
            <Carousel.Slide key={proposal.id}>
                  <Card component={Link} to={proposal.link} withBorder px="md" py="xs">
                    <Tooltip
                  position="top"
                  withinPortal
                  label={
                  <Group justify="space-between">
                          <Stack gap={5}>
                            <Text size="xs" lineClamp={1} className={classes.label}>
                              Voting Start
                            </Text>
                            <Group gap={5}>
                              <DateTime size="xs" date={proposal.votingStartTime || 'invalid'} format="L" />
                              <DateTime
                          size="xs"
                          fallback=""
                          invalidFallback=""
                          date={proposal.votingStartTime || 'invalid'}
                          format="LT"
                          c="dimmed"
                          ta="right" />

                            </Group>
                          </Stack>

                          <Stack gap={5}>
                            <Text size="xs" lineClamp={1} className={classes.label}>
                              Voting End
                            </Text>
                            <Group gap={5}>
                              <DateTime size="xs" date={proposal.votingEndTime || 'invalid'} format="L" />
                              <DateTime
                          size="xs"
                          fallback=""
                          invalidFallback=""
                          date={proposal.votingEndTime}
                          format="LT"
                          c="dimmed"
                          ta="right" />

                            </Group>
                          </Stack>
                        </Group>}>


                      <Stack gap="xs">
                        <Group justify="space-between">
                          <Text size="sm" fw={500} lineClamp={1}>
                            Proposal #{proposal.proposalId.toString()}
                          </Text>
                          <GovernanceProposalStatusBadge status={proposal.status} />
                        </Group>

                        <Stack gap={5}>
                          <Text size="xs" lineClamp={1} className={classes.label}>
                            Results
                          </Text>
                          <TallyResult votingResults={proposal.results} />
                        </Stack>
                        {proposal.turnout &&
                    <Stack gap={5}>
                            <Text size="xs" lineClamp={1} className={classes.label}>
                              Voting Turnout
                            </Text>
                            <ProgressWithSharedTooltip
                        sections={[
                        {
                          id: 'voting',
                          value: proposal.turnout.ratio * 100,
                          tooltip: (active) =>
                          <Text
                            key="voting"
                            size="sm"
                            fw={active ? 700 : 400}>
                            {`Total Voting Power ${NumberFormat.fractionalPercentage(
                              proposal.turnout!.ratio
                            )} (${NumberFormat.tokenAmount(
                              proposal.turnout!.totalVotingPower,
                              tokenSymbol
                            )})`}</Text>,

                          label: `${NumberFormat.fractionalPercentage(proposal.turnout.ratio)}`,
                          color: 'var(--observatory-color-score-high)'
                        },
                        {
                          id: 'bonded',
                          value: 100 - proposal.turnout.ratio * 100,
                          tooltip: () =>
                          <Text key="bonded" size="sm" fw={400}>{`Bonded Tokens (${NumberFormat.tokenAmount(
                              proposal.turnout!.votingEndBondedTokens,
                              tokenSymbol
                            )})`}</Text>,

                          color: 'gray.5'
                        }]} />


                          </Stack>}

                      </Stack>
                    </Tooltip>
                  </Card>
                </Carousel.Slide>
            )}
            </Carousel>
          </TiledGrid.Tile>}

      </TiledGrid>
    </LabeledCard>);

}