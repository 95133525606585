/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_token_tradingview_v1_authorize_post } from '../models/Body_token_tradingview_v1_authorize_post';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TradingviewService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Token
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public token(
        formData: Body_token_tradingview_v1_authorize_post,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tradingview/v1/authorize',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Symbol Info
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSymbolInfo(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tradingview/v1/symbol_info',
        });
    }

    /**
     * Token Price Historical Chart V2
     * @param symbol
     * @param to
     * @param resolution
     * @param from
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokenPriceHistoricalChartV2(
        symbol: string,
        to: number,
        resolution: string,
        from: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tradingview/v1/history',
            query: {
                'symbol': symbol,
                'to': to,
                'resolution': resolution,
                'from': from,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Streaming
     * @returns any Successful Response
     * @throws ApiError
     */
    public streaming(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tradingview/v1/streaming',
        });
    }

}
